<script>

    // @ts-nocheck

    import { settings } from '../../stores/settings.js';
    import { user } from '../../stores/user.js';
    import ButtonOk from '../button/ButtonOk.svelte';
    import ModalImpressum from '../modal/ModalImpressum.svelte';
    import Footer from '../footer/Footer.svelte';

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleOk = () => {
        $settings.view ="form";
    }

</script>

<div style="height:30px;"></div>

<div class="daa-main-layout">

    <div></div>

    <div>
       
        <div class="daa-welcome-headline">Willkommen!</div>
       
        <div class=" daa-welcome-container">
            <div class="daa-welcome-text">Im nächsten Schritt werden Sie gebeten, persönliche Angaben zu machen, die für die Sozialversicherung Ihrer Tätigkeit als Honorarkraft erforderlich sind. Bitte füllen Sie die Felder vollständig aus und klicken Sie jeweils am unteren Ende auf <b>SEITE SPEICHERN UND WEITER</b>.</div>
            <div class="daa-welcome-text">Bei fehlenden Informationen können Sie den Prozess unterbrechen, die Option „Das weiß ich nicht“ verwenden oder eine entsprechende Option in den Auswahlfeldern nutzen.</div>
            <div class="daa-welcome-text">Vielen Dank!</div>
        </div>

        <div class="daa-welcome-buttons-container">
            <ButtonOk active={true} label="Weiter zum Formular" callback={handleOk}></ButtonOk>
        </div>
       
    </div>

    <div></div>

 </div>

 <div style="height: 100px;"></div>


<Footer positionFix="true"></Footer>
<ModalImpressum></ModalImpressum>
