<script>

    // @ts-nocheck
    import { onMount, onDestroy } from 'svelte';  
    import { userObj } from '../../stores/userObj.js';
    import { minChars } from '../helper/validate.js';
    import { pages,panels, fields } from '../../stores/form.js';
    import { endpoints } from '../../stores/endpoints.js';
    import { user } from '../../stores/user.js';
    import { validation } from '../../stores/validation.js';
    import { settings } from '../../stores/settings.js';
    import Panel from '../panel/Panel.svelte';
    import ButtonOk from '../button/ButtonOk.svelte';
    import InputSelect from '../inputSelect/InputSelect.svelte';
    import InputSelectCountries from '../inputSelect/InputSelectCountries.svelte';
    import InputText from '../inputText/InputText.svelte';
    import InputTextUnknown from '../inputText/InputTextUnknown.svelte';
    import InputEmail from '../inputText/InputEmail.svelte';
    import InputDate from '../inputDate/InputDate.svelte';
    import InputStreet from '../inputStreet/InputStreet.svelte';
    import InputLocation from '../inputLocation/InputLocation.svelte';
    import Explanation from '../text/Explanation.svelte';
    import RadioButtonGroup from '../radio/RadioButtonGroup.svelte';
    import Footer from '../footer/Footer.svelte';
    
   // export let id = -1;
    $:pageKey = "page"+$settings.page;
    $:pageDescription = $pages[pageKey];
    $:nextPage = Math.min($settings.page +1, 6);
    let panelComponents = {};

    $:btnActive = true;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleOk = () => {

        $settings.tree = userObj.tree;
        let pageOk = startValidation();
        //console.log("pageOK is", pageOk)
       
        if (pageOk !== "valid") {
            handlePageNotValid();
        }
        else {
            if ($settings.validatePanel) { // test mode
                goToNextPage();
            }
            else {
                savePage();
            }
        }
    }

    const handlePageNotValid = () => {
        $settings.showWarningInFooter = true;
    }

    const goToNextPage = () => {
        nextPage = Math.min($settings.page +1, 6);
        $settings.page = nextPage;
    }

    const handleLogoutButton = () => {
        logout();
    }

    const startValidation = () => {

        if ($settings.page === 4) {
            let result = validatePage4();
            return result;
        }

        if ($settings.page === 5) {
            let result = validatePage5();
            return result;
        }
        
        let panelsValid = "valid";
        for (let [key, component] of Object.entries(panelComponents)) {
            let validateResult = component.validatePanel();
            if (validateResult === "invalid") {
                panelsValid = "invalid";
            }
        }
        return panelsValid;
    }

    const validatePage4 = () => {
       
        let isValid;

        const validEnds = [
            "P_A_A_A", "P_A_A_B", "P_A_B_A", "P_A_B_B",
            "P_B_A_A", "P_B_A_B", 
            "P_B_B_A", "P_B_B_B", 
            "P_B_C_A", "P_B_C_B",
            "P_B_D_A", "P_B_D_B",
            "P_B_E_A", "P_B_E_B",
            "P_C_A", "P_C_B", "P_C_B_A", "P_C_B_B", "P_C_C",
            "P_D_A", "P_D_B",
            "P_E"
        ]
      
        if( validEnds.indexOf($settings.tree) > -1) {
           
            isValid = "valid";

            //console.log("in page settings tree:.", $settings.tree);

            // special cases:
            if($settings.tree === "P_C_B" || $settings.tree === "P_C_B_A" || $settings.tree === "P_C_B_B") {
                //console.log("special case detected")
                let ch = userObj.BefreiungsbescheidDRV;
                if(ch !== "yes" && ch !== "no") {
                    isValid = "invalid";
                }
            }
            return isValid;
        }
        return "invalid";
    }

    const validatePage5 = () => {

        let v1 = "invalid";
        let v2 = "invalid";
        
        const validEnds = [
            "Mini_A_A", "Mini_A_B", "Mini_B"
            ]
        if( validEnds.indexOf(userObj.Mini) > -1) v1 = "valid";
       
        const validEndsAddQ = [
            "AddQ_A", "AddQ_B", "AddQ_C"
        ]
        if( validEndsAddQ.indexOf(userObj.AddQ) > -1) v2 = "valid";

        if(v1 === "valid" && v2 === "valid") return "valid"
        return "invalid";
        }
    
    ////////////////////////////////// logout ///////////////////////////////////////////////////////////////

    async function logout () {

        const response = await fetch($endpoints.EPpath+$endpoints.EPlogout, {
            method:"POST",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
            body: JSON.stringify({
                "token": $settings.token
            })
        });

        const resp = await response.json();

        if (resp.status === false) {
            console.log("no proper logout");
            finishLogout();
        } else {
            finishLogout();
        }
    }

    const finishLogout = () => {
        $settings.token = false;
        $settings.role = false;
        $settings.view = "login";
    }
    
   
    ////////////////////////////////// save page ////////////////////////////////////////////////////////////

     async function savePage() {

        // console.log("save page", userObj);

        const response = await fetch($endpoints.EPpath+$endpoints.EPpage+$settings.page, {
            method:"POST",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
            body: JSON.stringify(userObj)
        });

        const resp = await response.json();

        // console.log("save user response:::", resp);

        let checkResp = resp.message.includes("successfully")
        if (!checkResp) {
            console.log("save NOT successfull")
            //handleUnsuccess();
        } else {
            handleSuccess();
        }
    }

    const handleSuccess = () => {
        goToNextPage();

    }


    export const update = () => {
        for (let [key, component] of Object.entries(panelComponents)) {
            component.update();
        }
    }

    onMount( () => {
       // update();
        $settings.showWarningInFooter = false;
        //console.log("page load", userObj);
	});

    onDestroy(() => {
        
	});

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>


<div class="daa-form-page"> 
    {#each pageDescription.panels as componentKey}
        <Panel id={componentKey} bind:this={panelComponents[componentKey+""]}></Panel>
    {/each}
</div> 

<div class="daa-form-footer">

    {#if $settings.showWarningInFooter}
    <div class="daa-page-footer-warning ">Es fehlen noch Angaben</div>
    {/if}
   
    <ButtonOk active={true} label="Seite speichern und weiter" callback={handleOk}></ButtonOk>
</div>



<!---------- Logout ------------>

<div class="daa-logout-container">
   <button class="daa-logout-container-btn"
   on:click={handleLogoutButton}>
        Logout
    </button>
</div>
