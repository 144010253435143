import { writable } from 'svelte/store';

export const settings = writable({

    view:"login",
    role:"superAdmin",
    userSortBy: "lastName",
    userSortDirection: "asc",
    token: false,
    page: 1,
    groupId: 1,
    groupsId: -1,
    showWarningInFooter: false,
    allowTreeStart: true,
    validatePanel: false,
    pagination: {},
    tree:"",
});

