<script>

    // @ts-nocheck

    import { settings } from '../../stores/settings.js';
    import { endpoints } from '../../stores/endpoints.js';
    import { user } from '../../stores/user.js';
    import { userObj } from '../../stores/userObj.js';
    import { sendDocs } from '../../stores/sendDocs.js';
    import SendDocs from '../sendDocs/SendDocs.svelte';
    import ButtonOk from '../button/ButtonOk.svelte';
    import ModalImpressum from '../modal/ModalImpressum.svelte';
    import Footer from '../footer/Footer.svelte';

    export let id;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleOk = () => {
        //$settings.view ="form";
    }

    const handleLogoutButton = () => {
        logout();
    }

     ////////////////////////////////// logout ///////////////////////////////////////////////////////////////

     async function logout () {

        const response = await fetch($endpoints.EPpath+$endpoints.EPlogout, {
            method:"POST",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
            body: JSON.stringify({
                "token": $settings.token
            })
        });

        const resp = await response.json();

        if (resp.status === false) {
            console.log("no proper logout");
            finishLogout();
        } else {
            finishLogout();
        }
    }

    const finishLogout = () => {
        $settings.token = false;
        $settings.role = false;
        $settings.view = "login";
    }

</script>


<div style="height:30px;"></div>

<div class="daa-main-layout">

    <div></div>
    <div>
        <div class="daa-welcome-headline" style="padding:10px;">Vielen Dank!</div>
    </div>
    <div></div>

 </div>

 <SendDocs store={userObj}></SendDocs>

 <div class="daa-main-layout">

    <div></div>
    <div>
        <div class="daa-welcome-container">
            <div class="daa-welcome-text">Hier noch ein Hinweis: Sie können sich jederzeit wieder am Portal anmelden und Änderungen an Ihren Daten vornehmen.</div>
        </div>
        <div class="daa-welcome-headline" style="padding:10px;">Auf Wiedersehen!</div>
    </div>
    <div></div>
 </div>

 <div style="height: 40px;"></div>


<!-- <Footer positionFix="true"></Footer>
<ModalImpressum></ModalImpressum> -->

<!---------- Logout ------------>

<div class="daa-logout-container">
    <button class="daa-logout-container-btn"
    on:click={handleLogoutButton}>
         Logout
     </button>
 </div>
