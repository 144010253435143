
<script>

    import { onMount } from 'svelte';
    import { settings } from '../../stores/settings.js';
    import { user, dummyUser, currentUserId } from '../../stores/user.js';
    import { endpoints } from '../../stores/endpoints.js';
    import { userObj } from '../../stores/userObj.js'; 
    import { modal } from "../../stores/modal";  
    import { fields } from '../../stores/form.js';
    import Icon from "../icon/Icon.svelte";
    import IconButton from "../icon/IconButton.svelte";

    $:fieldNames = {};

    console.log("Fixed",$currentUserId.id);
    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

     let fieldData = {}; // Initialize as an empty object to avoid undefined errors
      fields.subscribe(value => {
        fieldData = value;
      });

  const allowedFields = [
        'firstName',
        'lastName',
        'username',
        'eMail',
        'employeeID',
        'namePrefix',
        'title',
        'countryName',
        'zipCode',
        'location',
        'street',
        'nationality',
        'gender',
        'schoolGraduation',
        'jobGraduation',
        'IBAN',
        'BIC',
        'foreignIBAN',
        'differentAccountOwner',
        'taxId',
        'additionalOccupation',
        'taxClass',
        'SSN',
        'birthDate',
        'birthName',
        'birthLocation',
        'birthNamePrefix',
        'birthCountry',
        'healthInsuranceType',
        'healthInsuranceCompany',
        'healthInsuranceCompanyPrivate',
        'healthInsuranceOperatingId',
        'childNumberUnder25'
    ];


      // Initial filtered user data
       let filteredUserData = {};

       // Function to create a new object with only allowed fields
       const assignToObj = (respData) => {
           return Object.keys(respData).reduce((acc, key) => {
               if (allowedFields.includes(key)) {
                   acc[key] = respData[key];
               }
               return acc;
           }, {});
       };

    ////////////////////////////////// load  user ////////////////////////////////////////////////////////////

    async function loadUser () {

        const id = $currentUserId["id"];
        const response = await fetch($endpoints.EPpath+$endpoints.EPUser+"/"+id+"/detail", {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
        });

        const resp = await response.json();

        if (resp.status === false) {
            handleUnsuccess();

        } else {
            handleSuccess (resp.data)
        }
    }

    const handleUnsuccess = () => {
        console.log("load single user not successful")
    }

     const handleSuccess = (responseData) => {
          if (responseData) {
              filteredUserData = assignToObj(responseData);
              console.log(filteredUserData); // Log the filteredUserData
          }
      };

    onMount(async () => {
	     loadUser();
	});
</script>


<div class="daa-table-container">

 {#each Object.entries(filteredUserData) as [key, value]}

<div class="daa-table-row daa-table-admin-user-cols" role="none">
    <div class="daa-table-double-cell">
     <div class="daa-table-cell">
          {fieldData[key] ? fieldData[key].name : (key === 'username' ? 'Benutzername' : (key === 'employeeID' ? 'Mitarbeiter ID' : key))}
        </div>
   </div>
   <div class="daa-table-double-cell">
    {value}
    </div>

</div>

{/each}

</div>

<div class="daa-table-foot" >
   
</div> 
